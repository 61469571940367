<template>
  <div class="otp-container">
    <div class="input-group">
      <img
        class="logo"
        src="/icons/security.png"
        alt=""
      />
      <input
        v-model="localOTP"
        placeholder="Enter OTP"
        class="otp-input"
        type="text"
        maxlength="6"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localOTP: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.localOTP = newValue
    },
  },
  methods: {
    handleInput() {
      // Only allow numbers
      this.localOTP = this.localOTP.replace(/\D/g, '')
      this.$emit('input', this.localOTP)

      // Emit finish event when 6 digits are entered
      if (this.localOTP.length === 6) {
        this.$emit('finish')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  padding: 1.25rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #0c4da2;
}

.input-group {
  display: flex;
  gap: 1.25rem;
  width: 100%;
}

.logo {
  height: 40px;
}

.otp-input {
  flex: 1;
  border: none;
  border-bottom: 1px solid #6b7280;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
    border-bottom: 2px solid blue;
    border-bottom-color: #0c4da2;
  }
}
</style>
